// Reducer action types
export const STORE_USER_CHECK_REQUEST_INIT = 'STORE_USER_CHECK_REQUEST_INIT';
export const STORE_USER_CHECK_REQUEST_RESET = 'STORE_USER_CHECK_REQUEST_RESET';
export const STORE_USER_CHECK_REQUEST_FAILED = 'STORE_USER_CHECK_REQUEST_FAILED';
export const STORE_USER_CHECK_REQUEST_SUCCEEDED = 'STORE_USER_CHECK_REQUEST_SUCCEEDED';

export const STORE_USER_PASSWORD_EDIT_REQUEST_INIT = 'STORE_USER_PASSWORD_EDIT_REQUEST_INIT';
export const STORE_USER_PASSWORD_EDIT_REQUEST_RESET = 'STORE_USER_PASSWORD_EDIT_REQUEST_RESET';
export const STORE_USER_PASSWORD_EDIT_REQUEST_FAILED = 'STORE_USER_PASSWORD_EDIT_REQUEST_FAILED';
export const STORE_USER_PASSWORD_EDIT_REQUEST_SUCCEEDED = 'STORE_USER_PASSWORD_EDIT_REQUEST_SUCCEEDED';

export const STORE_USER_PROFILE_EDIT_REQUEST_INIT = 'STORE_USER_PROFILE_EDIT_REQUEST_INIT';
export const STORE_USER_PROFILE_EDIT_REQUEST_RESET = 'STORE_USER_PROFILE_EDIT_REQUEST_RESET';
export const STORE_USER_PROFILE_EDIT_REQUEST_FAILED = 'STORE_USER_PROFILE_EDIT_REQUEST_FAILED';
export const STORE_USER_PROFILE_EDIT_REQUEST_SUCCEEDED = 'STORE_USER_PROFILE_EDIT_REQUEST_SUCCEEDED';

export const STORE_USER_AVATAR_EDIT_REQUEST_INIT = 'STORE_USER_AVATAR_EDIT_REQUEST_INIT';
export const STORE_USER_AVATAR_EDIT_REQUEST_RESET = 'STORE_USER_AVATAR_EDIT_REQUEST_RESET';
export const STORE_USER_AVATAR_EDIT_REQUEST_FAILED = 'STORE_USER_AVATAR_EDIT_REQUEST_FAILED';
export const STORE_USER_AVATAR_EDIT_REQUEST_SUCCEEDED = 'STORE_USER_AVATAR_EDIT_REQUEST_SUCCEEDED';

export const STORE_USER_FACTORY_RESET_REQUEST_INIT = 'STORE_USER_FACTORY_RESET_REQUEST_INIT';
export const STORE_USER_FACTORY_RESET_REQUEST_RESET = 'STORE_USER_FACTORY_RESET_REQUEST_RESET';
export const STORE_USER_FACTORY_RESET_REQUEST_FAILED = 'STORE_USER_FACTORY_RESET_REQUEST_FAILED';
export const STORE_USER_FACTORY_RESET_REQUEST_SUCCEEDED = 'STORE_USER_FACTORY_RESET_REQUEST_SUCCEEDED';

// ======================================================== User check
// Set user check init data into store
export const storeUserCheckRequestInit = () => ({
    type: STORE_USER_CHECK_REQUEST_INIT
});

// Set user check failed data into store
export const storeUserCheckRequestFailed = ({message}) => ({
    message,
    type: STORE_USER_CHECK_REQUEST_FAILED
});

// Set user check succeeded data into store
export const storeUserCheckRequestSucceed = ({message}) => ({
    message,
    type: STORE_USER_CHECK_REQUEST_SUCCEEDED
});

// Set user check reset data into store
export const storeUserCheckRequestReset = () => ({
    type: STORE_USER_CHECK_REQUEST_RESET
});
// ======================================================== User password edit
// Set user password edit init data into store
export const storeUserPasswordEditRequestInit = () => ({
    type: STORE_USER_PASSWORD_EDIT_REQUEST_INIT
});

// Set user password edit failed data into store
export const storeUserPasswordEditRequestFailed = ({message}) => ({
    message,
    type: STORE_USER_PASSWORD_EDIT_REQUEST_FAILED
});

// Set user password edit succeeded data into store
export const storeUserPasswordEditRequestSucceed = ({message}) => ({
    message,
    type: STORE_USER_PASSWORD_EDIT_REQUEST_SUCCEEDED
});

// Set user password edit reset data into store
export const storeUserPasswordEditRequestReset = () => ({
    type: STORE_USER_PASSWORD_EDIT_REQUEST_RESET
});
// ======================================================== User profile edit
// Set user password edit init data into store
export const storeUserProfileEditRequestInit = () => ({
    type: STORE_USER_PROFILE_EDIT_REQUEST_INIT
});

// Set user password edit failed data into store
export const storeUserProfileEditRequestFailed = ({message}) => ({
    message,
    type: STORE_USER_PROFILE_EDIT_REQUEST_FAILED
});

// Set user password edit succeeded data into store
export const storeUserProfileEditRequestSucceed = ({message}) => ({
    message,
    type: STORE_USER_PROFILE_EDIT_REQUEST_SUCCEEDED
});

// Set user password edit reset data into store
export const storeUserProfileEditRequestReset = () => ({
    type: STORE_USER_PROFILE_EDIT_REQUEST_RESET
});
// ======================================================== User avatar edit
// Set user password edit init data into store
export const storeUserAvatarEditRequestInit = () => ({
    type: STORE_USER_AVATAR_EDIT_REQUEST_INIT
});

// Set user password edit failed data into store
export const storeUserAvatarEditRequestFailed = ({message}) => ({
    message,
    type: STORE_USER_AVATAR_EDIT_REQUEST_FAILED
});

// Set user password edit succeeded data into store
export const storeUserAvatarEditRequestSucceed = ({message}) => ({
    message,
    type: STORE_USER_AVATAR_EDIT_REQUEST_SUCCEEDED
});

// Set user password edit reset data into store
export const storeUserAvatarEditRequestReset = () => ({
    type: STORE_USER_AVATAR_EDIT_REQUEST_RESET
});
// ======================================================== User factory reset
// Set user factory reset init data into store
export const storeUserFactoryResetRequestInit = () => ({
    type: STORE_USER_FACTORY_RESET_REQUEST_INIT
});

// Set user factory reset failed data into store
export const storeUserFactoryResetRequestFailed = ({message}) => ({
    message,
    type: STORE_USER_FACTORY_RESET_REQUEST_FAILED
});

// Set user factory reset succeeded data into store
export const storeUserFactoryResetRequestSucceed = ({message}) => ({
    message,
    type: STORE_USER_FACTORY_RESET_REQUEST_SUCCEEDED
});

// Set user factory reset reset data into store
export const storeUserFactoryResetRequestReset = () => ({
    type: STORE_USER_FACTORY_RESET_REQUEST_RESET
});