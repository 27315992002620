import Lodash from "lodash";

import * as actions from "./actions";

// Partial global store for users data management
const initialState = {
    page: 1,
    list: [],
    hasMoreData: false,

    current: {
        id: '', name: '', reference: '', map: '', description: '', creation: '',

        collector: {id: '', name: '', phone: ''},

        agents: [],
    }
};

// Reduce
function reduce(state = initialState, action) {
    let nextState;
    switch (action.type) {
        // Resolve event to set zones data
        case actions.STORE_SET_ZONES_DATA:
            nextState = {...state, list: action.zones, page: action.page, hasMoreData: action.hasMoreData};
            return nextState || state;
        // Resolve event to set zone data
        case actions.STORE_SET_ZONE_DATA:
            nextState = {...state, current: action.zone};
            if(action.alsoInList) {
                nextState = {
                    ...nextState,
                    list: Lodash.map(nextState.list, (item) => {
                        if(item.id === action.zone.id) item = action.zone;
                        return item;
                    })
                };
            }
            return nextState || state;
        // Resolve event to set new zone data
        case actions.STORE_SET_NEW_ZONE_DATA:
            nextState = {...state, list: [action.zone, ...state.list]}
            return nextState || state;
        // Resolve event to set next zones data
        case actions.STORE_SET_NEXT_ZONES_DATA:
            nextState = {...state, list: [...state.list, ...action.zones], page: action.page, hasMoreData: action.hasMoreData};
            return nextState || state;
        // Resolve event to stop infinite scroll zones data,
        case actions.STORE_STOP_INFINITE_SCROLL_ZONES_DATA:
            nextState = {...state, hasMoreData: false};
            return nextState || state;
        // Unknown action
        default: return state;
    }
}

export default reduce